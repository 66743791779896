import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "../Button"
import { InputField, TextArea } from "../FormElements"
import styled from "styled-components"
import { P } from "../../styles/typography"
import { Form } from "./style"

const schema = yup.object().shape({
  name: yup.string(),
  email: yup
    .string()
    .email("Check your Email")
    .required("This field is required"),
  message: yup.string().required("This field is required"),
})

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUseNativeValidation: false,
  })
  const [loading, setLoading] = useState(false)
  const [serverResponse, setServerResponse] = useState(null)

  const onSubmit = (data) => {
    fetch(`/api/contact`, {
      method: `POST`,
      body: JSON.stringify(data),
      headers: {
        "content-type": `application/json`,
      },
    })
      .then((res) => {
        setLoading(true)
        reset()
        return res.json()
      })
      .then((body) => {
        setLoading(false)
        setServerResponse(body.message)
        // setTimeout(() => {
        //   setServerResponse(null)
        // }, 5000)
        reset()
        return body
      })
  }

  // console.log(errors)
  return serverResponse ? (
    <P>{serverResponse}</P>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        label="Your Name"
        name="name"
        type="text"
        register={register}
        error={errors.name?.message}
      />
      <InputField
        type="email"
        name="email"
        label="Your Email"
        required
        register={register}
        error={errors.email?.message}
      />
      <TextArea
        label="Your Message"
        name="message"
        required
        register={register}
        rows={5}
        error={errors.message?.message}
      />
      <Button type="submit" disabled={loading}>
        Send Message
      </Button>
    </Form>
  )
}

export default ContactForm
