import React, { useContext } from "react"
import Layout from "../layouts/default"
import { H1, H2 } from "../styles"
import Page from "../components/Page"
import usePageHeader from "../hooks/usePageHeader"
import {
  Address,
  ContactPageWrapper,
  Map,
  QuickLink,
  SectionTitle,
} from "../styles/contact-page"
import ContactForm from "../components/ContactForm"
import loadable from "@loadable/component"
import { PhiDispatchContext } from "../context"
import SEO from "../components/seo"

const PhiMap = loadable(() => import("../components/Map"))

export default function ContactPage({ data }) {
  const [pageHeaderRef, pageHeaderHeight] = usePageHeader()
  const dispatch = useContext(PhiDispatchContext)

  return (
    <Layout>
      <SEO title="Contact Phi" />
      <Page as="main" pageHeaderHeight={pageHeaderHeight}>
        <Page.Header as="header" columnWidth={1280} ref={pageHeaderRef}>
          <Page.Subtitle>We are always here to help</Page.Subtitle>
          <H1 as={H2}>Contact Phi</H1>
        </Page.Header>
        <ContactPageWrapper>
          <div
            css={`
              grid-area: form;
            `}
          >
            <SectionTitle as="h2">
              Please write your question, or any feedback you would like us to
              know
            </SectionTitle>
            <ContactForm />
          </div>
          <div
            css={`
              grid-area: qlinks;
            `}
          >
            <SectionTitle as="h2">More ways to connect</SectionTitle>
            <QuickLink
              as="button"
              style={{ width: "100%" }}
              onClick={() => dispatch({ type: "TOGGLE_EARLY_ACCESS_POPUP" })}
            >
              <QuickLink.Subtitle>Stay in the Loop</QuickLink.Subtitle>
              <QuickLink.Title>Get our Newsletter</QuickLink.Title>
            </QuickLink>
            <QuickLink
              rel="noopener noreferrer"
              target="_blank"
              href="https://phi.nolt.io/"
              title='Phi on nolt.io'
            >
              <QuickLink.Subtitle>Share your Ideas</QuickLink.Subtitle>
              <QuickLink.Title>Request a Feature</QuickLink.Title>
            </QuickLink>
          </div>
          <div
            css={`
              grid-area: map;
            `}
          >
            <Map>
              <Map.Box>
                <PhiMap />
                {/* <Map.Image src="/phimap@2x.png" alt="" /> */}
              </Map.Box>
              <Address>
                <Address.Text>Phenometry P.C.</Address.Text>
                <Address.Text>
                  6 Apollonos str.
                  <br />
                  10557, Athens <br />
                  Greece <br />
                  Tel. +302103240390
                </Address.Text>
              </Address>
            </Map>
          </div>
        </ContactPageWrapper>
      </Page>
    </Layout>
  )
}
