import styled from "styled-components"
import { LabelWrapper } from "../FormElements/styles"

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.4rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  ${LabelWrapper} {
    &:nth-of-type(3) {
      grid-area: auto / span 2;

      @media (max-width: 768px) {
        grid-area: auto;
      }
    }
  }

  button {
    justify-self: start;
  }
`
