import { transitions } from "polished"
import styled from "styled-components"
import Container from "../components/Container"
import { ar, _size } from "./functions"
import { P, Small } from "./typography"

export const ContactPageWrapper = styled(Container)`
  padding-top: 7.2rem;
  padding-bottom: 7.2rem;
  display: grid;
  gap: clamp(5.6rem, (96 - 1280) * 100vw, 9.6rem);
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "form qlinks" "map map";

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: "form" "qlinks" "map";
  }
`

export const SectionTitle = styled(Small)`
  color: ${({ theme }) => theme.colors.greyLight};
  margin-bottom: 3.2rem;
`

export const QuickLink = styled.a`
  display: block;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.greyDark};
  height: 11rem;
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  ${transitions(["background-color"], ".2s ease")};

  & + & {
    margin-top: 1.6rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.offBlack};
  }
`

const QuickLinkSubtitle = styled(Small)`
  color: ${({ theme }) => theme.colors.greyLight};
  margin-bottom: 0.6rem;
`

const QuickLinkTitle = styled(P)`
  color: ${({ theme }) => theme.colors.offWhite};
`

QuickLink.Subtitle = QuickLinkSubtitle
QuickLink.Title = QuickLinkTitle

export const Map = styled.div`
  border-radius: 0.4rem;
  overflow: hidden;
  display: grid;

  @media (max-width: 768px) {
    display: block;
  }
`

export const MapBox = styled.div`
  grid-area: 1 / 1;
  position: relative;
  z-index: 1;
  ${ar(21, 9, true)};

  @media (max-width: 768px) {
    ${ar(1, 1, true)};
  }
`
const MapLeaflet = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .leaflet-marker-icon {
    background: transparent;
    border: 0;
  }
`

const MapImage = styled.img`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-position: center;
  object-fit: cover;
`

const MapMarker = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: hsla(44, 93%, 57%, 1);
`

Map.Leaf = MapLeaflet
Map.Marker = MapMarker
Map.Image = MapImage
Map.Box = MapBox

export const Address = styled.address`
  grid-area: 1 / 1;
  z-index: 2;
  align-self: end;
  justify-self: start;
  font-style: normal;
  padding: 2.4rem;
  border-radius: 0.4rem;
  background: ${({ theme }) => theme.colors.black};
  transform: translate(1.6rem, -1.6rem);
  width: 24rem;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  small:nth-of-type(1) {
    margin-bottom: 0.6rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    transform: translate(0);
  }
`

const AddressText = styled(Small)``

Address.Text = AddressText
